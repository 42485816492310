export enum BlockTypes {
  TEXT = 'text',
  BOLD = 'bold',
  ITALIC = 'italic',
  LIST = 'list',
  LIST_NUMERIC = 'list_numeric',
  HEADER = 'header',
  SUBHEADER = 'subheader',
  LINK = 'link',
  IMAGE = 'image',
  BLOCK = 'block',
  SMALL_HEADER = 'small_header',
  SMALL_HEADER_ITALIC = 'small_header_italic',
  LIGHT_HEADER = 'light_header',
}

export type ContentBlock =
  | {
      type: Exclude<
        BlockTypes,
        | BlockTypes.LINK
        | BlockTypes.BLOCK
        | BlockTypes.LIST
        | BlockTypes.IMAGE
        | BlockTypes.LIST_NUMERIC
      >;
      text: string;
    }
  | {
      type: BlockTypes.LINK;
      text: string;
      url: string;
    }
  | {
      type: BlockTypes.BLOCK | BlockTypes.LIST | BlockTypes.LIST_NUMERIC;
      content: ContentBlock[];
    }
  | {
      type: BlockTypes.IMAGE;
      url: string;
      alt: string;
    };

export enum ACTags {
  FROM_PT = 'YN',
  FROM_TOOLKIT = 'Toolkit',
  FROM_FOOTER = 'Footer',
  FROM_SOWE = 'Sowe page',
}

export type RoutesItem = {
  path: string;
  name: string;
  slug?: string;
  isFooter?: string;
  isHeader?: boolean;
  nameLocale?: string;
};

export type BlogItemShort = {
  id: number;
  image: string;
  imageAlt: string;
  title: string;
  description: string;
  slug: string;
  key: string;
};

export type BlogItemLong = BlogItemShort & {
  content: ContentBlock[];
  contentImage: string;
  published?: string;
  updated?: string;
  outdated?: boolean;
  meta: {
    keywords: string;
    og?: {
      image?: string;
    };
  };
};

export type Post = {
  id: number;
  encodedTitle: string;
};

export type ContentType = {
  id: number;
  ordering: number; //Rang
  title: string;
  image: string;
  link: string; //for external link
};

export type Routes = Record<string, RoutesItem>;

export enum FreshSubscriptionType {
  NONMARKETING = '1',
  NEWSLETTERS = '2',
  PROMOTIONAL = '3',
  UPDATES = '4',
  EVENTS = '5',
}
