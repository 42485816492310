import { Post } from '../types';

export const POSTS: Post[] = [
  {
    id: 0,
    encodedTitle: 'sowe-whats-behind-the-rising-attention',
  },
  {
    id: 1,
    encodedTitle: 'residential-banking',
  },
  {
    id: 2,
    encodedTitle: 'lose-without-playing',
  },
  {
    id: 3,
    encodedTitle: 'kyc-mougli',
  },
  {
    id: 4,
    encodedTitle: 'one-car-on-the-route',
  },
  {
    id: 5,
    encodedTitle: 'assetsliabilitiesrisks',
  },
  {
    id: 6,
    encodedTitle: 'manyfaced-citizen',
  },
  {
    id: 7,
    encodedTitle: 'human-factor',
  },
  {
    id: 8,
    encodedTitle: 'tell-your-own-story',
  },
  {
    id: 9,
    encodedTitle: 'safe-deposit-boxes-convenience-or-capital-trap',
  },
  {
    id: 10,
    encodedTitle: '100-in-focus',
  },
  {
    id: 11,
    encodedTitle: 'psychoanalysts-couch',
  },
  {
    id: 12,
    encodedTitle: 'why-trusted-persons-will-not-replace-you',
  },
  {
    id: 13,
    encodedTitle: 'traditional-actions',
  },
  {
    id: 14,
    encodedTitle: 'information-asymmetry',
  },
  {
    id: 15,
    encodedTitle: 'accidental-toxicity',
  },
  {
    id: 16,
    encodedTitle: 'revocation-of-the-presumption-of-innocence',
  },
  {
    id: 17,
    encodedTitle: 'eliminating-information-asymmetry',
  },
  {
    id: 18,
    encodedTitle: 'kyc-not-about-me',
  },
  {
    id: 19,
    encodedTitle: 'game-over',
  },
  {
    id: 20,
    encodedTitle: 'ill-think-about-that-tomorrow',
  },
  {
    id: 21,
    encodedTitle: 'your-proxys-personal-life-your-risk',
  },
  {
    id: 22,
    encodedTitle: 'vertical-and-horizontal-family-capital',
  },
  {
    id: 23,
    encodedTitle: 'cryptocurrency',
  },
  {
    id: 24,
    encodedTitle: 'swift',
  },
  {
    id: 25,
    encodedTitle: 'the-alien-own-strategy-securing-your-familys-future',
  },
  {
    id: 26,
    encodedTitle: 'follow-the-trail',
  },
  {
    id: 27,
    encodedTitle: 'ski-slopes',
  },
  {
    id: 28,
    encodedTitle: 'red-button',
  },
  {
    id: 29,
    encodedTitle: 'the-rearview-mirror-or-the-history-of-your-money',
  },
  {
    id: 30,
    encodedTitle: 'rhino-running',
  },
  {
    id: 31,
    encodedTitle: 'playing-by-one-sides-rules',
  },
  {
    id: 32,
    encodedTitle: 'a-timeless-classic-in-a-new-way',
  },
].sort((a, b) => a.id - b.id);

export const POSTS_LENGTH = POSTS.length;

export const ANNOUNCMENTS = [
  {
    title: 'yansnotesAnnouncments.0',
  },
  {
    title: 'yansnotesAnnouncments.1',
  },
  {
    title: 'yansnotesAnnouncments.2',
  },
  {
    title: 'yansnotesAnnouncments.3',
  },
];
