import { ContentType } from '../types';

const imageSrc = '/images/toolkit/content';
export const TOOLKIT_DATA: ContentType[] = [
  {
    id: 0,
    ordering: 1,
    title: 'Assets Data Repositories And Wealth Transfer',
    image: imageSrc + '1' + '.webp',
    link: '/repository-presentation',
  },
  {
    id: 1,
    ordering: 2,
    title: 'Family guide weathering the storm',
    image: imageSrc + '2' + '.webp',
    link: '/family-capital-guide',
  },
  {
    id: 2,
    ordering: 3,
    title: 'Past and Present: Preventing Capital Loss in Transfers',
    image: imageSrc + '3' + '.webp',
    link: '/before-and-now/',
  },
  {
    id: 3,
    ordering: 4,
    title: 'Penguin Analytics',
    image: imageSrc + '4' + '.webp',
    link: '/penguin-analytics',
  },
  {
    id: 4,
    ordering: 5,
    title: 'Penguin Analytics shortcuts',
    image: imageSrc + '5' + '.webp',
    link: '/penguin-analytics-shortcuts',
  },
  {
    id: 5,
    ordering: 7,
    title: 'Capital Founders Global Survey',
    image: imageSrc + '7' + '.webp',
    link: 'https://ownerone.surveysparrow.com/s/2304-Founders/tt-cprTzL3oTvttuH46TSv342',
  },
  {
    id: 6,
    ordering: 8,
    title: "Global Survey for Capital Founders' Spouses",
    image: imageSrc + '8' + '.webp',
    link: 'https://ownerone.surveysparrow.com/s/1104-For-Wives/tt-6QUFQHn9PMBv856kLDd1hk?survey_name=for_wives',
  },
  {
    id: 7,
    ordering: 9,
    title: "Global Survey for Capital Founders' Kids ",
    image: imageSrc + '9' + '.webp',
    link: 'https://ownerone.surveysparrow.com/s/2404-For-Jun/tt-gFSBeWoJaoYAQERKLL3RCq?survey_name=for_jun',
  },
  {
    id: 8,
    ordering: 0,
    title: 'Crash Test For Family Capital',
    image: imageSrc + '10.webp',
    link: '/toolkit/crash-test-for-family-capital',
  },
  {
    id: 9,
    ordering: -1,
    title: 'Source of Wealth Essay Template',
    type: 'link',
    image: imageSrc + '11.webp',
    link: '/toolkit/sowe-template/',
  },
].sort((a, b) => a.ordering - b.ordering) as ContentType[];
