import { ReadonlyURLSearchParams } from 'next/navigation';
import { ACTags, FreshSubscriptionType } from './types';
import { api } from '.';
import { getDefaultLanguage } from '@/utils/languages';

export const stringToLetters = (text: string) => {
  let wordLength = text.split(' ');

  return wordLength.map((i, k) => {
    return (
      <span key={k} className="word">
        {i}
      </span>
    );
  });
};

export const encodeTitle = (str: string) => {
  return encodeURI(
    str
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .replace(/\s+/g, '-')
  );
};

export const getByString = (path: string, obj: any) => {
  return path?.split('.').reduce((p, c) => (p && p[c]) || null, obj);
};

const formatter = new Intl.NumberFormat('ru', {
  style: 'decimal',
});

export const formatNumbers = (n: number) => {
  return formatter.format(n);
};

export const throttle = <T,>(fn: (...args: T[]) => void, wait: number) => {
  let time = Date.now();
  return function (...args: T[]) {
    if (time + wait - Date.now() < 0) {
      fn(...args);
      time = Date.now();
    }
  };
};

export const getUtmObject = (searchParams: ReadonlyURLSearchParams) => {
  return {
    utm_campaign: searchParams.get('utm_campaign'),
    utm_medium: searchParams.get('utm_medium'),
    utm_source: searchParams.get('utm_source'),
    utm_content: searchParams.get('utm_content'),
    utm_term: searchParams.get('utm_term'),
  };
};

const mapUtmSourceToPrefixedKeys = (
  source: ReturnType<typeof getUtmObject>,
  prefix: string
): Record<string, string | null> => {
  if (!source) {
    return {};
  }
  return {
    [`${prefix}Campaign`]: source.utm_campaign,
    [`${prefix}Medium`]: source.utm_medium,
    [`${prefix}Source`]: source.utm_source,
    [`${prefix}Content`]: source.utm_content,
    [`${prefix}Term`]: source.utm_term,
  };
};

export const preapreUtmFields = (searchParams: ReadonlyURLSearchParams) => {
  const firstTouch = JSON.parse(localStorage.getItem('firstTouch') ?? 'null');
  const lastTouch = JSON.parse(localStorage.getItem('lastTouch') ?? 'null');
  const subscription = JSON.parse(
    sessionStorage.getItem('subscription') ?? 'null'
  );

  const fallbackSource = getUtmObject(searchParams);

  const subscriptionSource = subscription ?? fallbackSource;
  const firstTouchSource = firstTouch ?? fallbackSource;
  const lastTouchSource = lastTouch ?? fallbackSource;

  return {
    ...mapUtmSourceToPrefixedKeys(subscriptionSource, 'subscription'),
    ...mapUtmSourceToPrefixedKeys(firstTouchSource, 'firstTouch'),
    ...mapUtmSourceToPrefixedKeys(lastTouchSource, 'lastTouch'),
  };
};

export const getUtmFull = (searchParams: ReadonlyURLSearchParams) => {
  const obj = getUtmObject(searchParams);
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([name, value]) => {
    if (value) {
      params.append(name, value);
    }
  });

  return params.toString();
};

const getCookie = (name: string) => {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');
    if (key === name) {
      return value;
    }
  }
  return null;
};

export const subscribe = async (
  email: string,
  subsForm: ACTags,
  searchParams: ReadonlyURLSearchParams,
  newsletters: boolean = true
) => {
  const obj = {
    email,
    subsForm,
    subscriptionTypes: newsletters
      ? Object.values(FreshSubscriptionType).join(';')
      : FreshSubscriptionType.PROMOTIONAL,
    utm: getUtmFull(searchParams),
    language: getCookie('NEXT_LOCALE') ?? 'en', // httpOnly is false
    ...preapreUtmFields(searchParams),
  };

  try {
    await api.post('/subscribe', obj);
    return true;
  } catch (e) {
    return false;
  }
};

export const getDefaultVideoLanguage = (lang: string): string => {
  const availableLanguages = ['en', 'es', 'ru'];
  return availableLanguages.includes(lang) ? lang : 'en';
};
