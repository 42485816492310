import { Routes } from './types';
import { TOOLKIT_DATA } from './consts/toolkit';
import { VISIBLE_BLOG_POSTS } from './consts/blog';
import { POSTS } from './consts/yansnotes';
import { getTranslations } from 'next-intl/server';

const routes: Routes = {
  home: {
    path: '/',
    slug: 'home',
    name: 'home',
  },

  //! Recourses
  legend: {
    path: '/legend/',
    slug: 'legend',
    name: 'Legend',
    nameLocale: 'menuLinks.legend',
    isFooter: 'col-1',
    isHeader: true,
  },

  // Added like in design
  yansnotes: {
    path: '/yansnotes/',
    name: 'Yan’s Notes',
    nameLocale: 'menuLinks.notes',
    isHeader: false,
    isFooter: 'col-2',
  },

  analytics: {
    path: '/analytics/',
    slug: 'analytics',
    name: 'Analytics',
    nameLocale: 'menuLinks.analytics',
    isFooter: 'col-2',
    isHeader: true,
  },

  whyOwnerOne: {
    path: '/why-owner-one/',
    slug: 'why-owner-one',
    name: 'Why Owner.One',
    nameLocale: 'menuLinks.whyownerone',
    isHeader: true,
  },

  product: {
    path: '/product/',
    slug: 'product',
    name: 'How it works',
    nameLocale: 'menuLinks.hiw',
    isFooter: 'col-1',
    isHeader: true,
  },

  aboutUs: {
    path: '/about-us/',
    slug: 'about-us',
    name: 'About Us',
    nameLocale: 'menuLinks.about',
    isFooter: 'col-1',
    isHeader: true,
  },

  toolkit: {
    path: '/toolkit/',
    slug: 'toolkit',
    name: 'Toolkit',
    nameLocale: 'menuLinks.toolkit',
    isHeader: true,
    isFooter: 'col-2',
  },

  pricing: {
    path: '/start-using/',
    slug: 'pricing',
    name: 'Start using',
    nameLocale: 'menuLinks.startUsing',
    isFooter: 'col-1',
    isHeader: true,
  },

  blog: {
    path: '/blog/',
    slug: '/blog/:slug',
    name: 'Blog',
    nameLocale: 'menuLinks.blog',
    isFooter: 'col-2',
    isHeader: false,
  },

  //! Legal
  privacyPolicy: {
    path: '/privacy-policy/',
    slug: 'privacy-policy',
    name: 'Privacy Policy',
    nameLocale: 'menuLinks.privacy',
    isFooter: 'col-3',
  },

  cookiesPolicy: {
    path: '/cookies-policy/',
    slug: 'cookies-policy',
    name: 'Cookies Policy',
    nameLocale: 'menuLinks.cookies',
    isFooter: 'col-3',
  },

  termsOfService: {
    path: '/terms-of-service/',
    slug: 'terms-of-service',
    name: 'Terms Of Service',
    nameLocale: 'menuLinks.tos',
    isFooter: 'col-3',
  },

  contacts: {
    path: '/contacts/',
    slug: 'contacts',
    name: 'Contacts',
    nameLocale: 'menuLinks.contacts',
    isFooter: 'col-3',
  },

  beforeAndNow: {
    path: '/before-and-now/',
    slug: 'before-and-now',
    name: 'Before and now',
    nameLocale: 'menuLinks.beforeAndNow',
  },

  faq: {
    path: '/faq/',
    slug: 'faq',
    name: 'FAQ',
    nameLocale: 'menuLinks.FAQ',
  },

  quiz: {
    path: '/quiz/',
    slug: 'quiz',
    name: 'Quiz',
    nameLocale: 'menuLinks.quiz',
  },
};

TOOLKIT_DATA.forEach((item) => {
  if (!item.link || !item.link.startsWith('/')) return;

  routes[item.title] = {
    path: item.link,
    slug: item.link,
    name: item.title,
  };
});

VISIBLE_BLOG_POSTS.forEach((item) => {
  routes[item.title] = {
    path: `/blog/${item.slug}/`,
    slug: item.slug,
    name: item.title,
  };
});

export async function generateRoutes() {
  const t = await getTranslations('yansnotesPosts');

  POSTS.forEach((item) => {
    routes[t(`${item.encodedTitle}.title`)] = {
      path: `/yansnotes/${item.encodedTitle}/`,
      slug: item.encodedTitle,
      name: t(`${item.encodedTitle}.title`),
    };
  });

  return routes;
}
